import React, { useState } from "react"
import * as styles from './Event.module.scss';

import UserLockSVG from "../../icons/fontawesome/light/user-lock.svg";
import UsersSVG from "../../icons/fontawesome/light/users.svg";

import Layout from "../../page-components/layout"
import { graphql, Link } from "gatsby"
import RcDefaultPageTeaser from "../../page-components/teaser/rcDefaultPageTeaser"
import ChevronLeftSVG from "../../icons/fontawesome/light/chevron-left.svg";
import EventContent from "./EventContent"
import EventAttendeeForm from "./EventAttendeeForm"
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api"
import { GatsbyImage } from "gatsby-plugin-image"
import LocationSVG from "../../icons/fontawesome/light/location-circle.svg"
import TimeSVG from "../../icons/fontawesome/light/business-time.svg"
import RcDateTime from "../../page-components/helper/rcDateTime"
import RcDateTimeUntil from "../../page-components/helper/rcDateTimeUntil"
import NewsletterIframe from "../../pages-parts/newsletter-iframe/newsletter-iframe"
import { ICalendar } from 'datebook'
import RcButton from "../../components/button/rcButton"

const EventPage = ( { data, pageContext } ) => {

  const event = data.event;
  const eventDate = event?.acf_elements.date ? (new Date(Number.parseFloat(event.acf_elements.date) * 1000)) : null;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyC6M0Z6DyeWTvU_sAguqesvjGHj2O4n3Es"
  });

  let date = event.acf_elements.date;

  if (typeof date === "string") {
    date = new Date(date * 1000);
  }

  let untilDate = undefined;

  if (event.acf_elements?.dateuntil) {
    const splitted = event.acf_elements?.dateuntil.split(" ");
    const time = splitted[1].split(":");

    let untilDate = new Date();
    untilDate.setHours(time[0]);
    untilDate.setMinutes(time[1]);
  }

  let description = null;
  const webinarLink = event.acf_elements?.webinarlink ? "Hier Ihr Online-Link: " + event.acf_elements.webinarlink : "";

  if (event.acf_elements?.typ === "online") {
    description = "Danke für Ihre Anmeldung. Bitte melden Sie sich 3 - 5 Minuten vor Beginn an. Wir starten pünktlich. Danke für Ihr Verständnis. " + webinarLink;
  } else if (event.acf_elements?.typ === "hybrid") {
    description = "Danke für Ihre Anmeldung. Bitte melden Sie sich 3 - 5 Minuten vor Beginn an. Wir starten pünktlich. Danke für Ihr Verständnis. " + webinarLink + " Wenn Sie bei dieser Veranstaltung persönlich am Veranstaltungsort teilnehmen, dann ersuchen wir Sie, gleich zu Beginn anwesend zu sein. Wir starten pünktlich. Danke für Ihr Verständnis";
  } else {
    description = "Danke für Ihre Anmeldung. Wir ersuchen Sie, zum geplanten Veranstaltungsbeginn anwesend zu sein. Wir starten pünktlich";
  }

  const icalConfig = {
    title: event.title,
    location: event.acf_elements?.location,
    description,
    start: eventDate,
    end: untilDate
  }

  const icalendar = new ICalendar(icalConfig)

  return <Layout>

    <RcDefaultPageTeaser className={styles.teaser}>

      <div className="row">
        <div className="col-12 col-lg-6">
          <Link to={"/events"} className={"text-black mb-2 d-flex align-items-center"}>
            <ChevronLeftSVG className={"mr-1 svgFill"} />
            Zurück
          </Link>

          <h1 className={styles.caption + " mt-3 "}>{ event.title }</h1>

          <div className={styles.headerInfos + " mt-3"}>

              <div className={"d-flex align-items-center"}>
                {event.acf_elements?.location &&
                <>
                  <LocationSVG className={"mr-2 svgFill"}/>
                  {event.acf_elements.location}
                </>
                }
              </div>

              <div className={"d-flex align-items-center "  + styles.subcaption}>
                {event.acf_elements?.date &&
                <>
                  <TimeSVG className={"mr-2 svgFill"}/>
                  <RcDateTime date={date}/>
                  <RcDateTimeUntil until={event.acf_elements?.dateuntil} />
                </>
                }
              </div>


              {event.acf_elements?.speakers &&
              <>
                <UsersSVG className={"mr-2 svgFill"} />
                { event.acf_elements.speakers }
              </>
              }

              { event.acf_elements.maxattendee &&
                <div className={"d-flex align-items-center " + styles.maxAttendee}>
                  <UserLockSVG className={"mr-2"} />
                  begrenzte Teilnehmeranzahl ({ event.acf_elements.maxattendee})
                </div>
              }

              {event.acf_elements.price &&
              <div className={"d-flex align-items-center mt-3 " + styles.maxAttendee}>
                {/*<EuroSVG className={"mr-2"} />*/}
                { event.acf_elements.price == 0 && "kostenloses Event" }
                { event.acf_elements.price != 0 && "kostenpflichtiges Event - " + event.acf_elements.price + " €" }
              </div>
              }

              <div className={"mt-3"}>
                <RcButton small={true}
                          onClick={ () => { icalendar.download() } }>
                  iCal Download
                </RcButton>
              </div>

          </div>

        </div>

        <div className="col-12 col-lg-6 mt-3 mt-lg-0">
          <GatsbyImage image={event.acf_elements.header.localFile.childImageSharp.gatsbyImageData} />
        </div>
      </div>

    </RcDefaultPageTeaser>

    <EventContent event={event} />

    { event.acf_elements.showattendeelist && <EventAttendeeForm event={event} /> }

    { (event.acf_elements.typ === "physical" ||event.acf_elements.typ === "hybrid")  && event.acf_elements.address &&
      <div className={"container bg-white py-4"}>
        <div className={"row"}>
          <div className="col-12">

            <h4 className={"mb-2"}>Adresse</h4>

            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={ {
                  width: '100%',
                  height: '600px'
                } }
                center={ {
                  lat: event.acf_elements.address.latitude,
                  lng: event.acf_elements.address.longitude
                }}
                zoom={13}
              >

                <InfoWindow
                  position={{
                    lat: event.acf_elements.address.latitude,
                    lng: event.acf_elements.address.longitude
                  }}
                >
                  <div style={ { background: "white", borderRadius: 10 } }>
                    <p>{event.acf_elements.location}</p>
                  </div>
                </InfoWindow>

              </GoogleMap>
            ) : <></>
            }

          </div>
        </div>
      </div>
    }

    <div className="container bg-baby-blue pt-4">
      <div className="row">
        <div className="col-12">

          <NewsletterIframe />

        </div>
      </div>
    </div>

  </Layout>
};

export default EventPage;

export const query = graphql`
  query wpEventQuery($id: String!) {
    event: wpEvent(id: { eq: $id }) {
      id
      databaseId
      slug
      title
      content
      acf_elements {
        header {
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
        }
        location
        description
        date
        typ
        price
        hidden
        webinarlink
        showattendeelist
        maxattendee
        speakers
        address {
          latitude
          longitude
        }
      }
    }
  }
`
