import React from 'react';
import * as styles from "./newsletter-iframe.module.scss";

import IframeResizer from 'iframe-resizer-react';

const NewsletterIframe = ( { className = "" } ) => {

  return <div className={className + " bg-blue " + styles.wrapper}>

    <IframeResizer
      style={ { width: '100%', margin: "auto" } }
      src={"https://app.mailjet.com/widget/iframe/6BZ9/KV9"} />

  </div>

};

export default NewsletterIframe;
