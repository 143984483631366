import React from 'react';
import { graphql, useStaticQuery } from "gatsby"

const EventExplanation = ( { className = '' }) => {

  const data = useStaticQuery(graphql`
    query EventExplanationQuery {
      wp {
        aboEventTexte {
          acf {
            event
          }
        }
      }
    }
`);

  return <div className={className}
    dangerouslySetInnerHTML={
      {
        __html: data.wp.aboEventTexte.acf.event
      }
   } />

};

export default EventExplanation;
