import React, { useEffect, useState } from "react"
import * as styles from "./Event.module.scss"
import { useAuthContext } from "../../provider/authProvider"
import { currencyPipe } from "../../helper/currencyPipe"
import RcButton from "../../components/button/rcButton"
import { STRIPE_PK } from "../../helper/constants"
import { loadStripe } from "@stripe/stripe-js/pure"
import { getStrapiUrl } from "../../helper/uri"
import * as axios from "axios";
import { toast } from 'react-toastify';
import EventExplanation from "../../pages-parts/abo/eventExplanation"
import AboExplanation from "../../pages-parts/abo/aboExplanation"

const stripePromise = loadStripe(STRIPE_PK);

const EventContent = ( { event } ) => {

  const authContext = useAuthContext();

  const now = new Date().getTime();
  const eventDate = event?.acf_elements?.date ? (new Date(Number.parseFloat(event.acf_elements.date) * 1000)).getTime() : 0;

  const isPremium = event ? (event.acf_elements?.price > 0 || (eventDate <= now)) : false;
  const [ showPaywall, setShowPaywall ] = useState(isPremium);
  const [ activated, setActivated ] = useState(false);



  useEffect(
    () => {

      const action = async () => {

        if (!authContext.currentUser) {
          return;
        }

        if (activated) {
          return;
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const success = urlParams.get("success");
        if (success) {
          await authContext.activateEvent(event.databaseId);
          setActivated(true);
        }

      };

      action();
    },
    [ authContext.currentUser ]
  );

  useEffect(
    () => {

      if (authContext.currentUser) {
        const databaseId = event.databaseId;

        if ((eventDate <= now) && authContext.currentUser.premium) {
          setShowPaywall(false);
          return;
        }

        for (const e of authContext.currentUser.bought_events) {
          if (e.wordpressId == databaseId) {
            setShowPaywall(false);
            return;
          }
        }
      }

      // in all cases
      // check for "accesstoken" get param
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      const accesstoken = urlParams.get("accesstoken");

      if (accesstoken) {
        // check if already 15 days
        const nowIn15Days = (new Date().getTime()) + (1000 * 60 * 60 * 24 * 15);
        if (eventDate <= nowIn15Days) {
          setShowPaywall(false);
          return;
        }
      }

    },
    [ authContext.currentUser ]
  )

  const handleBuy = async () => {
    const stripe = await stripePromise;
    const res = await axios.post(getStrapiUrl() + "/checkout", { databaseId: event.databaseId });

    const result = await stripe.redirectToCheckout({
      sessionId: res.data.id,
    });

    if (result.error) {
      toast(result.error.message);
    }
  };

  const handleRedeem = async () => {
    const res = await axios.post(getStrapiUrl() + "/redeem-event/" + event.databaseId);
    await authContext.loadUserDetail();
  };

  const handleAbo = async () => {
    const url = typeof window !== 'undefined' ? window.location.href : '';

    const stripe = await stripePromise;
    const res = await axios.post(getStrapiUrl() + "/checkout-abo", { redirectUrl: url });

    const result = await stripe.redirectToCheckout({
      sessionId: res.data.id,
    });

    if (result.error) {
      toast(result.error.message);
    }
  };

  return <div className="container">
    <div className="row h-100">
      <div className={"col-12 py-5 bg-white h-100 px-5 " + (showPaywall ? styles.paywallContainer : "") }>

        { showPaywall && <div className={styles.paywall + " p-5"} >

          <div className={styles.paywallContent}>
            { (event.acf_elements?.price > 0 && !(eventDate <= now)) &&
            <>
              <h2>Dieses Event ist kostenpflichtig</h2>
              {!authContext.currentUser?.includedWebinars &&
              <p>Für {currencyPipe(event.acf_elements.price)} kaufen und lebenslang Zugriff erhalten.</p>
              }

              <EventExplanation />
            </>
            }

            { (eventDate <= now) &&
            <>
              <h2>Dieser Event ist Bestandteil der RC Academcy</h2>
              <AboExplanation />
            </>
            }


            {!authContext.currentUser &&
            <div className="mt-3">
              <RcButton className={"mr-2"}
                        onClick={ () => authContext.showRegisterModal() }>
                Registrieren
              </RcButton>

              <RcButton onClick={ () => authContext.showLoginModal() }>
                Login
              </RcButton>
            </div>
            }

            { (authContext.currentUser && (event.acf_elements?.price > 0 && !(eventDate <= now))) &&
              <>
                {!authContext.currentUser.includedWebinars &&
                <RcButton onClick={handleBuy}
                          className={"mt-3"}>
                  Jetzt Event-Zugang kaufen
                </RcButton>
                }

                {authContext.currentUser.includedWebinars > 0 &&
                <RcButton onClick={handleRedeem}
                          className={"mt-3"}>
                  Event einlösen (Abo-Kontingent)
                </RcButton>
                }
              </>
            }

            {authContext.currentUser && !(event.acf_elements?.price > 0 && !(eventDate <= now)) &&
            <RcButton onClick={handleAbo}
                      className={"mt-3"}>
              Jetzt Abo abschließen
            </RcButton>
            }

          </div>

        </div>
          }

        <div className={"content"} dangerouslySetInnerHTML={ { __html: event.content }} />

      </div>
    </div>
  </div>;
};

export default EventContent;
