import React, { useState } from "react"
import * as styles from './EventAttendeeForm.module.scss';

import RcFormErrors from "../../page-components/helper/rcFormErrors"
import { Formik } from "formik"
import { useAuthContext } from "../../provider/authProvider"
import RcButton from "../../components/button/rcButton"
import RcLoadingModal from "../../page-components/modal/rcLoadingModal"
import axios from "axios"
import { getStrapiUrl } from "../../helper/uri"
import * as Yup from "yup"
import { toast } from 'react-toastify';

const EventAttendeeForm = ( { event, className = '' } ) => {

  const authContext = useAuthContext();
  const [ sent, setSent ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const formSchema = Yup.object().shape(
    {
      mail: Yup.string().email("Ungültige Mail-Adresse").required("E-Mail Adresse notwendig"),
      firstname: Yup.string().required("Vorname notwendig"),
      lastname: Yup.string().required("Nachname notwendig"),
      attendees: Yup.number().required("Bitte geben Sie die genaue Anzahl der Teilnehmer ein")
    }
  );

  return <>

    <RcLoadingModal isOpen={loading} />

    <div className={"container w-100"}>

      <div className={"row"}>
          <div className={styles.formWrapper + " p-3 p-lg-5 w-100 " + className}>

            <h2>Anmeldung zum Event</h2>

            {!sent &&

            <Formik
              initialValues={
                {
                  mail: '',
                  firstname: '',
                  lastname: '',
                  company: '',
                  attendees: 1
                }
              }

              validationSchema={formSchema}

              onSubmit={
                async (values) => {
                  setLoading(true);

                  try {
                    await axios.post(getStrapiUrl() + "/submit-contact/event",
                      {
                        databaseId: event.databaseId,
                        title: event.title,
                        art: "Anmeldung zum Event",
                        ...values
                      }
                    );
                    setSent(true);
                  } catch(err) {
                    toast("Es ist ein unbekannter Fehler passiert.");
                  } finally {
                    setLoading(false);
                  }

                }
              }
            >
              {
                ({
                   values,
                   handleChange,
                   handleBlur,
                   handleSubmit,

                   touched,
                   errors
                 }) => {
                  return <form className={"rc-form"}>

                    <div className="rc-form-control">

                      <label htmlFor="mail">E-Mail*</label>

                      <input name="mail"
                             id="mail"
                             type="email"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             value={values.mail}/>

                    </div>

                    <div className="rc-form-control">

                      <label htmlFor="firstname">Vorname*</label>

                      <input name="firstname"
                             id="firstname"
                             type="text"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             value={values.firstname}/>

                    </div>

                    <div className="rc-form-control">

                      <label htmlFor="lastname">Nachname*</label>

                      <input name="lastname"
                             id="lastname"
                             type="text"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             value={values.lastname}/>

                    </div>

                    <div className="rc-form-control">

                      <label htmlFor="company">Firma</label>

                      <input name="company"
                             id="company"
                             type="text"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             value={values.company}/>

                    </div>

                    <div className="rc-form-control">

                      <label htmlFor="attendees">Anzahl Teilnehmer</label>

                      <input name="attendees"
                             id="attendees"
                             type="number"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             value={values.attendees}/>

                    </div>

                    <RcFormErrors touched={touched} errors={errors} className={"mb-2"} />


                    <RcButton onClick={handleSubmit} className={"d-inline-block mt-2 mb-4"}>
                      Anmelden
                    </RcButton>

                  </form>
                }
              }
            </Formik>

            }

            {
              sent &&
                <p>Sie wurden erfolgreich für das Event angemeldet.</p>
            }

          </div>
      </div>

    </div>
  </>;

};

export default EventAttendeeForm;
